<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab active>
      <template #title>
        <feather-icon
          icon="CpuIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">2D To 3D - AI</span>
      </template>

      <package2-d3-d />
    </b-tab>

<!-- pricing plan cards
    <b-tab>
      <template #title>
        <feather-icon
          icon="ActivityIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Incidental Findings - AI</span>
      </template>
    </b-tab>


    <b-tab>
      <template #title>
        <feather-icon
          icon="DatabaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Cloud Storage</span>
      </template>
    </b-tab>
   -->
  </b-tabs>

</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Package2D3D from './Package2D3D.vue'

export default {
  components: {
    BTabs,
    BTab,
    Package2D3D
  },
  data() {
    return {

    }
  },
  beforeCreate() {
    //this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
