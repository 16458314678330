<template>
  <b-card>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1>
        Packages
      </h1>
      <p class="mb-2 pb-75">
        All plans include advanced tools and features to boost your product. Choose the best package to fit your needs.
      </p>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="4">
            <b-card
              class="card-pricing"
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.basicPlan.img"
                :src="pricing.basicPlan.img"
                class="mb-2"
                alt="basic svg img"
                style="max-height: 200px; text-align: center; padding-top:32px; padding-bottom:32px;"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.basicPlan.monthlyPrice:pricing.basicPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold"></sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} </small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
              >
              Choose
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              class="popular card-pricing"
              align="center"
            >
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1"
                alt="svg img"
                style="max-height: 200px; text-align: center;padding-top:16px; padding-bottom:16px;"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.standardPlan.monthlyPrice:pricing.standardPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold"></sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.standardPlan.yearlyPlan.totalAnual }} </small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
              >
                Choose
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
            class="card-pricing"
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.enterprisePlan.img"
                :src="pricing.enterprisePlan.img"
                class="mb-2"
                alt="enterprise svg img"
                style="max-height: 200px; text-align: center;"
              />
              <!--/ img -->
              <h3>{{ pricing.enterprisePlan.title }}</h3>
              <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.enterprisePlan.monthlyPrice:pricing.enterprisePlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold"></sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.enterprisePlan.yearlyPlan.totalAnual }} / year</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data,index) in pricing.enterprisePlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
              >
                Choose
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->


    <!-- pricing faq -->
    <div class="pricing-faq">
      <hr />

      <b-row class="py-1">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
        <!--
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        -->
        <p class="mb-2 pb-75">
        If you have any questions or want to directly contact our sales, just feel free to reach out to us at <b>sales@dentaltwin.de</b>
      </p>
      <img src="sepa.png" style="height:40px;margin:8px"/>
      <img src="mastercard.png" style="height:40px;margin:8px" />
      <img src="paypal.png" style="height:40px;margin:8px" />
      <img src="visa.png" style="height:40px;margin:8px" />
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</b-card>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
    }
  },
  created() {
   this.pricing = {
        basicPlan: {
          title: 'Basic',
          //img: require('@/assets/images/illustration/Pot1.svg'),
          img: require('@/assets/images/illustration/smartmockups_small.png'),
          subtitle: 'Small start for everyone',
          monthlyPrice: 299,
          yearlyPlan: {
            perMonth: 0,
            totalAnual: 0,
          },
          planBenefits: [
            '1 case',
          ],
          popular: false,
        },
        standardPlan: {
          title: 'Standard',
          //img: require('@/assets/images/illustration/Pot2.svg'),
          img: require('@/assets/images/illustration/smartmockups_medium.png'),
          subtitle: 'For medium businesses',
          monthlyPrice: 1420,
          yearlyPlan: {
            perMonth: 40,
            totalAnual: 480,
          },
          planBenefits: [
            '5 cases',
          ],
          popular: true,
        },
        enterprisePlan: {
          title: 'Enterprise',
          //img: require('@/assets/images/illustration/Pot3.svg'),
          img: require('@/assets/images/illustration/smartmockups_big.png'),
          subtitle: 'For big organizations',
          monthlyPrice: 6745,
          yearlyPlan: {
            perMonth: 80,
            totalAnual: 960,
          },
          planBenefits: [
            '25 cases',
          ],
          popular: false,
        },
        qandA: [
        {
                      question: 'I am not happy with the results, what should I do?',
                      ans:
                        'We are working on a ticket system, for now please use help@dentaltwin.de and explain why you are not happy with the generated results.',
                    },
                    {
                      question: 'Is this product really using AI or is it just a marketing-claim?',
                      ans:
                        'Yes we are using true AI technologies in our products. Our systems are based on Deep Learning and advanced algorithms to deliver the best and most reliable quality possible.',
                    },
                    
                    {
                      question: 'How can I use your services?',
                      ans:
                        'You can use the software from every modern browser, also on tablets and mobile phones.',
                    },
        ],
      }
    
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style>
.card-body
{
  box-shadow: 1px 1px 16px 1px rgba(0,0,0,0.5) !important
}
</style>